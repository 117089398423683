
  @if (isMultipleFilter) {
    <form [formGroup]="formControl" class="basic-form">
      <div class="row">
        <div class="col">
          <div class="row">
            @for(keypar of formField|keyvalue;track $index)
              {
                @if (keypar.key == 'store'|| keypar.key == 'name') {
                  <div class="col-auto d-flex align-items-center mb-2">

                    <label for="{{keypar.value}}">{{'TABLES.'+ keypar.key | translate}}</label>
                    <mat-form-field class="multiple-field">

                      <input type="text"
                             aria-label="Number"
                             matInput
                             [formControlName]="keypar.key"
                             [matAutocomplete]="auto">
                      <mat-autocomplete #auto="matAutocomplete">
                        @for (option of filteredOptions | async; track option) {
                          <mat-option  [value]="option.name">{{option.name}}</mat-option>
                        }
                      </mat-autocomplete>
                                <!-- <input formControlName="store" title="input" matInput >
                                @if (formFields.get('store')?.hasError('required')) {
                                  <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
                                } -->
                      </mat-form-field>
                  </div>

                } @else if (keypar.key == 'city') {
                  <div class="col-auto d-flex align-items-center mb-2">
                    <label for="{{keypar.value}}">{{'TABLES.'+ keypar.key | translate}}</label>
                    <mat-form-field class="multiple-field">

                      <input type="text"
                             aria-label="Number"
                             matInput
                             formControlName="city"
                             [matAutocomplete]="auto">
                      <mat-autocomplete #auto="matAutocomplete">
                        @for (option of filteredCity | async; track option) {
                          <mat-option (click)="addOption(option , 'city')" [value]="option.name">{{option.name}}</mat-option>
                        }
                      </mat-autocomplete>
                                <!-- <input formControlName="store" title="input" matInput >
                                @if (formFields.get('store')?.hasError('required')) {
                                  <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
                                } -->
                              </mat-form-field>

                  </div>

                } @else {
                  <div class="col-auto d-flex align-items-center mb-2">
                    <label for="{{keypar.value}}">{{'TABLES.'+ keypar.key | translate}}</label>
                    <input id="{{keypar.value}}" class="form-control" [title]="keypar.key" matInput [formControlName]="keypar.key">

                  </div>

                }
            }

          </div>

        </div>
        <div class="col-auto text-end d-flex align-items-center mb-2">
          <button class="m-0 btn-new" (click)="sendFilters()" type="submit">{{'ACTIONS.search' | translate}}</button>
        </div>

      </div>
      <!-- <mat-form-field>
        <mat-label>Toppings</mat-label>
        <mat-chip-set>
          <mat-chip *ngFor="let topping of toppingsControl.value"
            [removable]="true" (removed)="onToppingRemoved(topping)">
            {{ topping }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-set>
        <mat-autocomplete  #autoCompany="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onCompanySelected($event.option.value)">
          <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
            {{ company.name }}
          </mat-option>
        </mat-autocomplete>


      </mat-form-field> -->


    </form>

  }
  @if (isReady) {
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
      class="mat-elevation-z8 basic-form-table" [class]="cssClasses + ' ' + rowClass">

      @for (item of displayedColumns; track $index) {

        <ng-container [matColumnDef]="item">
          @if (item == 'status') {
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">
              {{'TABLES.'+  item | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container  *ngIf="element.isNew; else display">
                <mat-form-field>
                  <mat-select placeholder="{{'TABLES.'+  item | translate}}" name="s" id="" class="status"  matInput title="title" [(ngModel)]="outputData[item]">

                      <mat-option  [value]="'STATUS.active' | translate">{{'STATUS.active' | translate}}</mat-option>
                      <mat-option  [value]="'STATUS.ko' | translate">{{'STATUS.ko' | translate}}</mat-option>
                      <mat-option  [value]="'STATUS.draft' | translate">{{'STATUS.draft' | translate}}</mat-option>

                  </mat-select>
                </mat-form-field>
                </ng-container>
                <ng-template #display><div [class]="formatStatus(element.status)" class="btn btn-lf">{{element.status.toUpperCase()}}</div></ng-template>


            </td>
          }@else if (item == 'type') {
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">
              {{'TABLES.'+  item | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container  *ngIf="element.isNew; else display">
                <mat-form-field>
                  <mat-select placeholder="{{'TABLES.'+  item | translate}}" name="s" id="" class="status"  matInput title="title" [(ngModel)]="outputData[item]">

                      <mat-option  [value]="'STORES.cat_empty' | translate">{{'STORES.cat_empty' | translate}}</mat-option>
                      <mat-option  [value]="'STORES.cat_vetrofania' | translate">{{'STORES.cat_vetrofania' | translate}}</mat-option>
                      <mat-option  [value]="'STORES.cat_stampa' | translate">{{'STORES.cat_stampa' | translate}}</mat-option>

                  </mat-select>
                </mat-form-field>
                </ng-container>
                <ng-template #display>{{element[item]}}</ng-template>


            </td>
          } @else if (item == 'created_at') {
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created_at">
              {{'TABLES.'+  item | translate}}
            </th>
            <td mat-cell *matCellDef="let element"> {{formatDateString(element[item])}} </td>
          } @else if (item == 'num_used') {
            <th style="max-width: 110px;" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created_at">
              {{'TABLES.'+  item | translate}}
            </th>
            <td style="max-width: 110px;" mat-cell *matCellDef="let element"> {{formatUsedWindows(element[item])}} </td>
          }@else if (item == 'anomaly') {
            <th style="max-width: 110px;" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created_at">
              {{'TABLES.'+  item | translate}}
            </th>
            <td style="max-width: 110px;" mat-cell *matCellDef="let element">
              <i class="icon-lf-alert icon-lg" [ngClass]=" {'disabled' : element[item] == false }"></i>
           </td>
          } @else if (item == 'warning') {
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created_at">
              {{'TABLES.'+  item | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container  *ngIf="element.isNew; else display">
                <mat-form-field>
                  <mat-select placeholder="{{'TABLES.'+  item | translate}}" name="s" id="" class="status"  matInput title="title" [(ngModel)]="outputData[item]">

                    <mat-option  [value]="0">{{'GENERAL.yes' | translate}}</mat-option>
                    <mat-option  [value]="1">{{'GENERAL.no' | translate}}</mat-option>

                </mat-select>                </mat-form-field>
              </ng-container>
              <ng-template #display>
                <i class="icon-lf-alert icon-lg" [ngClass]=" {'disabled' : element[item] == 0 }"></i>

              </ng-template>

            </td>
          }@else if (item == 'draft') {
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created_at">
              {{'TABLES.'+  item | translate}}
            </th>
            <td mat-cell *matCellDef="let element"> {{ element['internal_status'] }} </td>
          } @else if (item == 'availability') {
            <th style="min-width: 170px; width: 170px; max-width: 170px;" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by availability">
              {{'TABLES.'+  item | translate}}
            </th>

              <td mat-cell *matCellDef="let element" style="min-width: 170px; width: 170px;max-width: 170px">
                <ng-container *ngIf="element.isNew && isMultiple; else display">
                  <mat-form-field>
                    <mat-select multiple="true" placeholder="{{'TABLES.'+  item | translate}}" name="s" id="" title="title" matInput title="title" [(ngModel)]="outputData[item]" >
                      @for(month of months ; track $index) {
                        <mat-option [value]="month.value">{{month.label}}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                  </ng-container>
                  <ng-template #display>
                    <mat-select name="s" id="" multiple title="title" [(ngModel)]="element[item]">
                      @for(month of element[item] ; track $index) {
                        <mat-option disabled  [value]="month">{{transformDate(month)}}</mat-option>
                      }
                    </mat-select>
                  </ng-template>

              </td>

          } @else if (item == 'details') {
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by details">
            </th>
            <td mat-cell *matCellDef="let element" [class]="element.isNew? 'p-0 d-none' : null" [attr.colspan]="element.isNew? 0 : 1">
              <a class="detail" (click)="emitAction(element ,'detail')" >{{element[item]}}</a>
            </td>
          } @else if (item == 'export') {
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by export">
            </th>
            <td mat-cell *matCellDef="let element"> <a class="export" (click)="emitAction(element , 'export')" >{{element[item]}}</a>  </td>
          } @else if (item == 'crud') {
            <th style="min-width: 120px ;" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by crud">
            </th>
            <td mat-cell *matCellDef="let element" [class]="element.isNew? 'p-0' : null" [attr.colspan]="element.isNew? 0 : 1">
              @for(crud of element[item] | keyvalue; track crud.key) {

                <button class="btn" [class]="crud.key" (click)="emitAction(element , crud.key)" >
                  @if ((crud.key + "").includes("icon")) {
                    <i [class]="crud.value"></i>
                  } @else {
                    {{crud.value}}
                  }
                </button>
              }
              <!-- <a class="edit" (click)="emitAction(element , 'edit')" >{{crud.value}}</a>
              <a class="delete" (click)="emitAction(element , 'delete')" >{{element[item].delete}}</a> -->
            </td>
          } @else if (item == 'actions') {
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by actions">
            </th>
            <td mat-cell *matCellDef="let element" [attr.colspan]="element.isNew? 3 : 1">

              <ng-container *ngIf="element.isNew; else display">
                <button [disabled]="!checkRequiredField()" (click)="save()" class="btn btn-circle bg-confirm" >
                  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="check"></mat-icon>

                </button>
                <button (click)="removeData()" class="btn btn-circle bg-discard">
                  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
                </button>
                </ng-container>
              <ng-template #display>
                @for(actions of element[item] | keyvalue; track actions.key) {

                  <button class="btn" [class]="actions.key" (click)="emitAction(element , actions.key)" >
                    @if (actions.key == "icon") {
                      <i [class]="actions.value"></i>
                    } @else {
                      {{actions.value}}
                    }
                  </button>
                }
              </ng-template>

              <!-- <a class="edit" (click)="emitAction(element , 'edit')" >{{crud.value}}</a>
              <a class="delete" (click)="emitAction(element , 'delete')" >{{element[item].delete}}</a> -->
            </td>
          } @else {
            <th [style]="item == 'display_window_id'? 'max-width: 80px': null " mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by {{item}}">
              {{'TABLES.'+  item | translate}}
            </th>
            <td  mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.isNew; else display">
                <mat-form-field>
                  <input placeholder="{{'TABLES.'+  item | translate}}" matInput title="title" [(ngModel)]="outputData[item]">
                </mat-form-field>
                </ng-container>
                <ng-template #display>{{ element[item] }}</ng-template>
            </td>

          }
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    @if (addRow && addrowVisible) {
      <button (click)="addData()" class="mt-3 btn link" >{{'BUTTONS.add_windows' | translate}}</button>
      <!-- <app-insert-data-table></app-insert-data-table> -->
    }
    <ng-content>

    </ng-content>
    <!-- @if (showPaginator) {
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>

    } -->
  }

