<div class="content container-fluid pb-2 alert-component">
  <div class="lf-card px-0">
    <div class="d-flex justify-content-end button-wrapper">
      <button (click)="newAlert()" type="button" title="options" class="btn-new" >
        {{'BUTTONS.new_alert' | translate}}
      </button>

    </div>
    @if (isReady) {
      <app-basic-table
      [cssClasses]="''"
      [formField]="filters"
      [isMultipleFilter]="true"
      [tableData]="alertsData"
      [displayedColumns]="columns"
      (filters)="filterData($event)"
      (action)="actionReceiver($event)">
      <div class="mt-4">
        @if (alertsData.length == 0) {
            <div class="w-100 text-center">{{'GENERAL.no_data' | translate}}</div>
        }
        <basic-paginator [config]="configData" (paginator)="pageChanger($event)"></basic-paginator>

      </div>
    </app-basic-table>
    }
  </div>

  </div>


