import { Component, inject } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum Files {
  R = "Concessioni",
  S = "Farmacie",
  C = "Prodotti",
  D = "Vetrine"
}
@Component({
  selector: 'app-uploads',
  standalone: true,
  imports: [MaterialModule, SharedModule],
  templateUrl: './uploads.component.html',
  styleUrl: './uploads.component.scss'
})
export class UploadsComponent {
  fileType = Files;
  type: keyof typeof Files = 'R';
  fileRequest!: FormData;
  filename: string = "";
  constructor(    private apiSvc: ApiService,
    private snack : MatSnackBar

  ) {}
  onFileSelected(event: any): void {
    const file = event.target.files[0];

    //console.log(file);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', this.type);
    // this.filesToUpload.push({file:formData , param: "concessione"});
    //console.log(formData);
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    this.filename = event.target.files[0].name // read file as data url


    this.fileRequest = formData;
    console.log(event.target.files[0]);

  }

  confirmUpload() {

    this.apiSvc.uploadXls(this.fileRequest).subscribe((res: any) => {
      console.log(res);
      this.snack.open( res.message , "ok", {
        duration: 5000
      })
    })
    this.apiSvc.getAllProducts();
  }

}
